import WorkspaceFetcher from "./WorkspaceFetcher"
import { Synchronizer } from "../sync/Synchronizer"
import Workspace from "./Workspace"
import { HttpWorkspaceFetcher } from "./HttpWorkspaceFetcher"
import Logger from "../logger"

export class WorkspaceManager implements WorkspaceFetcher, Synchronizer {
  private workspace: Workspace | null

  constructor(private readonly httpWorkspaceFetcher: HttpWorkspaceFetcher) {
    this.workspace = null
  }

  get(): Workspace | null {
    return this.workspace
  }

  async sync(): Promise<void> {
    try {
      const workspace = await this.httpWorkspaceFetcher.fetchIfModified()
      if (workspace) {
        this.workspace = workspace
      }
    } catch (e) {
      Logger.log.error(`Failed to fetch workspace: ${e}`)
    }
  }

  async close(): Promise<void> {}
}
