import Workspace from "./Workspace"
import { Transport, TransportHeaders, TransportRequest, TransportResponse } from "../transport/Transport"
import { WorkspaceConfigDto } from "./dto"
import { TimerSample } from "../metrics/Timer"
import { ApiCallMetrics } from "../metrics/monitoring/MonitoringMetricRegistry"

export class HttpWorkspaceFetcher {
  private lastModified: string | null

  constructor(private readonly url: string, private readonly transport: Transport) {
    this.lastModified = null
  }

  async fetchIfModified(): Promise<Workspace | null> {
    const request = this.createRequest()
    const response = await this.execute(request)
    return this.handleResponse(response)
  }

  private createRequest(): TransportRequest {
    const builder = TransportRequest.builder().url(this.url).method("GET")
    if (this.lastModified !== null) {
      builder.addHeader(TransportHeaders.IF_MODIFIED_SINCE, this.lastModified)
    }
    return builder.build()
  }

  private async execute(request: TransportRequest): Promise<TransportResponse> {
    const sample = TimerSample.start()
    try {
      const response = await this.transport.send(request)
      ApiCallMetrics.record("get.workspace", sample, response)
      return response
    } catch (e) {
      ApiCallMetrics.record("get.workspace", sample, null)
      throw e
    }
  }

  private handleResponse(response: TransportResponse): Workspace | null {
    if (response.isNotModified()) {
      return null
    }
    if (!response.isSuccessful()) {
      throw new Error(`Http status code: ${response.statusCode}`)
    }
    if (response.body === null) {
      throw new Error("Response body is empty")
    }
    this.lastModified = response.headers.get(TransportHeaders.LAST_MODIFIED)
    const dto = JSON.parse(response.body) as WorkspaceConfigDto
    return Workspace.from(dto)
  }
}
