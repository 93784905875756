import EventProcessor from "./internal/event/EventProcessor"
import {
  Decision,
  DecisionReason,
  EventType,
  Experiment,
  FeatureFlagDecision,
  HackleEvent,
  HackleUser,
  InAppMessageDecision,
  MatchValueType,
  RemoteConfigDecision,
  VariationKey
} from "./internal/model/model"
import { EmptyParameterConfig, ParameterConfig } from "./internal/config/ParameterConfig"
import UserEvent from "./internal/event/UserEvent"
import Logger from "./internal/logger"
import WorkspaceFetcher from "./internal/workspace/WorkspaceFetcher"
import ObjectUtil from "./internal/util/ObjectUtil"
import TraceTransformer from "./internal/trace/TraceTransformer"
import { Comparable } from "./internal/util/Comparable"
import ExperimentEvaluator from "./internal/evaluation/evalautor/experiment/ExperimentEvaluator"
import RemoteConfigEvaluator from "./internal/evaluation/evalautor/remoteconfig/RemoteConfigEvaluator"
import ExperimentEvaluation from "./internal/evaluation/evalautor/experiment/ExperimentEvaluation"
import ExperimentRequest from "./internal/evaluation/evalautor/experiment/ExperimentRequest"
import { EvaluatorContext } from "./internal/evaluation/evalautor/Evaluator"
import RemoteConfigRequest from "./internal/evaluation/evalautor/remoteconfig/RemoteConfigRequest"
import {
  DelegatingManualOverrideStorage,
  ManualOverrideStorage
} from "./internal/evaluation/target/ManualOverrideStorage"
import DelegatingEvaluator from "./internal/evaluation/evalautor/DelegatingEvaluator"
import EvaluationFlowFactory from "./internal/evaluation/flow/EvaluationFlowFactory"
import UserEventFactory from "./internal/event/UserEventFactory"
import { Clock, SystemClock } from "./internal/util/TimeUtil"
import { InAppMessageEvaluator } from "./internal/evaluation/evalautor/iam/InAppMessageEvaluator"
import { InAppMessageRequest } from "./internal/evaluation/evalautor/iam/InAppMessageRequest"
import { TimerSample } from "./internal/metrics/Timer"
import { DecisionMetrics } from "./internal/metrics/monitoring/MonitoringMetricRegistry"
import InAppMessageHiddenStorage from "./internal/evaluation/target/InAppMessageHiddenStorage"

const log = Logger.log

/**
 * DO NOT use this class directly.
 * This class is only used internally by Hackle.
 * Backward compatibility is not supported.
 * Please use server-side or client-side HackleClient instead.
 */
export default class HackleCore {
  constructor(
    private readonly experimentEvaluator: ExperimentEvaluator,
    private readonly remoteConfigEvaluator: RemoteConfigEvaluator,
    private readonly inAppMessageEvaluator: InAppMessageEvaluator,
    private readonly workspaceFetcher: WorkspaceFetcher,
    private readonly eventFactory: UserEventFactory,
    private readonly eventProcessor: EventProcessor,
    private readonly errorDedupDeterminer: ErrorDedupDeterminer,
    private readonly clock: Clock
  ) {}

  static create(
    workspaceFetcher: WorkspaceFetcher,
    eventProcessor: EventProcessor,
    manualOverrideStorages: ManualOverrideStorage[],
    inAppMessageHiddenStorage: InAppMessageHiddenStorage
  ): HackleCore {
    const delegatingEvaluator = new DelegatingEvaluator()

    const manualOverrideStorage = new DelegatingManualOverrideStorage(manualOverrideStorages)
    const evaluationFlowFactory = new EvaluationFlowFactory(
      delegatingEvaluator,
      manualOverrideStorage,
      inAppMessageHiddenStorage
    )

    const experimentEvaluator = new ExperimentEvaluator(evaluationFlowFactory)
    const remoteConfigEvaluator = new RemoteConfigEvaluator(
      evaluationFlowFactory.remoteConfigParameterTargetRuleDeterminer
    )
    const inAppMessageEvaluator = new InAppMessageEvaluator(evaluationFlowFactory)

    delegatingEvaluator.add(experimentEvaluator)
    delegatingEvaluator.add(remoteConfigEvaluator)

    return new HackleCore(
      experimentEvaluator,
      remoteConfigEvaluator,
      inAppMessageEvaluator,
      workspaceFetcher,
      new UserEventFactory(SystemClock.instance),
      eventProcessor,
      new ErrorDedupDeterminer(),
      SystemClock.instance
    )
  }

  experiment(experimentKey: number, user: HackleUser, defaultVariation: VariationKey): Decision {
    if (!experimentKey) {
      log.error("experimentKey must not be empty")
      return Decision.of(defaultVariation, DecisionReason.INVALID_INPUT)
    }

    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready.")
      return Decision.of(defaultVariation, DecisionReason.SDK_NOT_READY)
    }

    const experiment = workspace.getExperimentOrNull(experimentKey)

    if (!experiment) {
      log.warn("Experiment does not exist.")
      return Decision.of(defaultVariation, DecisionReason.EXPERIMENT_NOT_FOUND)
    }

    const request = ExperimentRequest.of(workspace, user, experiment, defaultVariation)
    const [evaluation, decision] = this.experimentInternal(request)

    const events = this.eventFactory.create(request, evaluation)
    events.forEach((event) => this.eventProcessor.process(event))

    return decision
  }

  experiments(user: HackleUser): Comparable<Experiment, Decision> {
    const decisions = new Comparable<Experiment, Decision>((a, b) => a.id === b.id)
    const workspace = this.workspaceFetcher.get()
    if (!workspace) {
      return decisions
    }

    workspace.getExperiments().forEach((experiment) => {
      const request = ExperimentRequest.of(workspace, user, experiment, "A")
      const [_, decision] = this.experimentInternal(request)
      decisions.add(experiment, decision)
    })

    return decisions
  }

  private experimentInternal(request: ExperimentRequest): [ExperimentEvaluation, Decision] {
    const evaluation = this.experimentEvaluator.evaluate(request, EvaluatorContext.create())
    const config = evaluation.config ?? new EmptyParameterConfig()
    const decision = Decision.of(evaluation.variationKey, evaluation.reason, config)
    return [evaluation, decision]
  }

  featureFlag(featureKey: number, user: HackleUser): FeatureFlagDecision {
    if (!featureKey) {
      log.error("featureKey must not be empty")
      return FeatureFlagDecision.off(DecisionReason.INVALID_INPUT)
    }

    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready.")
      return FeatureFlagDecision.off(DecisionReason.SDK_NOT_READY)
    }

    const featureFlag = workspace.getFeatureFlagOrNull(featureKey)

    if (!featureFlag) {
      log.warn("FeatureFlag does not exist.")
      return FeatureFlagDecision.off(DecisionReason.FEATURE_FLAG_NOT_FOUND)
    }

    const request = ExperimentRequest.of(workspace, user, featureFlag, "A")
    const [evaluation, decision] = this.featureFlagInternal(request)

    const events = this.eventFactory.create(request, evaluation)
    events.forEach((event) => this.eventProcessor.process(event))

    return decision
  }

  featureFlags(user: HackleUser): Comparable<Experiment, FeatureFlagDecision> {
    const decisions = new Comparable<Experiment, FeatureFlagDecision>((a, b) => a.id === b.id)
    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      return decisions
    }

    workspace.getFeatureFlags().forEach((featureFlag) => {
      const request = ExperimentRequest.of(workspace, user, featureFlag, "A")
      const [_, decision] = this.featureFlagInternal(request)
      decisions.add(featureFlag, decision)
    })

    return decisions
  }

  private featureFlagInternal(request: ExperimentRequest): [ExperimentEvaluation, FeatureFlagDecision] {
    const evaluation = this.experimentEvaluator.evaluate(request, EvaluatorContext.create())
    const config: ParameterConfig = evaluation.config ?? new EmptyParameterConfig()
    const decision =
      evaluation.variationKey === "A"
        ? FeatureFlagDecision.off(evaluation.reason, config)
        : FeatureFlagDecision.on(evaluation.reason, config)
    return [evaluation, decision]
  }

  public inAppMessage(messageKey: number, user: HackleUser) {
    const workspace = this.workspaceFetcher.get()

    if (!workspace) {
      log.warn("SDK not ready.")
      return InAppMessageDecision.of(DecisionReason.SDK_NOT_READY)
    }

    const inAppMessage = workspace.getInAppMessageOrNull(messageKey)

    if (!inAppMessage) {
      log.warn("In app message does not exist.")
      return InAppMessageDecision.of(DecisionReason.IN_APP_MESSAGE_NOT_FOUND)
    }

    const request = InAppMessageRequest.of(workspace, user, inAppMessage, this.clock.currentMillis())
    const evaluation = this.inAppMessageEvaluator.evaluate(request, EvaluatorContext.create())
    return InAppMessageDecision.of(evaluation.reason, evaluation.inAppMessage, evaluation.message)
  }

  /**
   * Used in in-app message event trigger determiner
   */
  public tryInAppMessage(messageKey: number, user: HackleUser): InAppMessageDecision {
    const sample = TimerSample.start()
    let decision: InAppMessageDecision
    try {
      decision = this.inAppMessage(messageKey, user)
    } catch (e) {
      log.error(`Unexpected error while deciding in app message [${messageKey}]: ${e}`)
      decision = InAppMessageDecision.of(DecisionReason.EXCEPTION)
    }
    DecisionMetrics.inAppMessage(sample, messageKey, decision)
    return decision
  }

  track(event: HackleEvent, user: HackleUser, timestamp: number = new Date().getTime()) {
    if (!event) {
      log.warn("event must not be null.")
      return
    }

    if (typeof event !== "object") {
      log.warn("Event must be event type.")
      return
    }

    if (typeof event === "object") {
      if (!event.key || typeof event.key !== "string") {
        log.warn("Event key must be not null. or event key must be string type.")
        return
      }
    }

    const eventType = this.workspaceFetcher.get()?.getEventTypeOrNull(event.key) || new EventType(0, event.key)
    this.eventProcessor.process(UserEvent.track(eventType, event, user, timestamp))
  }

  trackException(exception: Error, user: HackleUser) {
    if (this.errorDedupDeterminer.isDedupTarget(exception)) {
      return
    }

    const event = TraceTransformer.toEvent(exception)

    this.flush()
    this.track(event, user)
    this.flush()
  }

  flush() {
    this.eventProcessor.flush(false)
  }

  remoteConfig(
    parameterKey: string,
    user: HackleUser,
    requiredType: MatchValueType,
    defaultValue: string | number | boolean
  ): RemoteConfigDecision {
    const workspace = this.workspaceFetcher.get()

    if (ObjectUtil.isNullOrUndefined(workspace)) {
      log.warn("SDK not ready.")
      return RemoteConfigDecision.of(defaultValue, DecisionReason.SDK_NOT_READY)
    }

    const remoteConfigParameter = workspace.getRemoteConfigParameterOrNull(parameterKey)

    if (ObjectUtil.isNullOrUndefined(remoteConfigParameter)) {
      log.warn("Remote config parameter does not exist.")
      return RemoteConfigDecision.of(defaultValue, DecisionReason.REMOTE_CONFIG_PARAMETER_NOT_FOUND)
    }

    const request = RemoteConfigRequest.of(workspace, user, remoteConfigParameter, requiredType, defaultValue)
    const evaluation = this.remoteConfigEvaluator.evaluate(request, EvaluatorContext.create())

    const events = this.eventFactory.create(request, evaluation)
    events.forEach((event) => this.eventProcessor.process(event))

    return RemoteConfigDecision.of(evaluation.value, evaluation.reason)
  }

  close(): void {
    this.eventProcessor.close()
  }
}

export class ErrorDedupDeterminer {
  private previous?: Error

  isDedupTarget(error: Error): boolean {
    if (this._isSameError(error, this.previous)) {
      return true
    }
    this.previous = error
    return false
  }

  _isSameError(currentError: Error, previousError?: Error): boolean {
    if (ObjectUtil.isNullOrUndefined(previousError)) {
      return false
    }
    return (
      currentError.name === previousError.name &&
      currentError.message === previousError.message &&
      currentError.stack === previousError.stack
    )
  }
}
