import Logger from "./core/internal/logger"
import HackleClientImpl, { BrowserHackleClient as HackleClient, DevTools, PageView } from "./hackle/index.browser"
import { isHackleWebApp, resolveHackleWebAppClientOrNull } from "./hackle/HackleWebAppClientImpl"
import {
  BROWSER_BATCH_SIZE,
  BROWSER_FLUSH_INTERVAL,
  COOKIE_EXPIRE_DAYS,
  IAM_HIDE_STORAGE_PREFIX,
  IAM_IMPRESSION_STORAGE_PREFIX,
  LOCAL_STORAGE_KEY_PREFIX,
  METRIC_FLUSH_INTERVAL,
  OVERRIDE_AB_STORAGE_PREFIX,
  OVERRIDE_FF_STORAGE_PREFIX,
  SPLIT_URL_REDIRECT_CHECK_COOKIE_EXPIRE_DAYS,
  SPLIT_URL_REDIRECT_CHECK_COOKIE_KEY
} from "./config"
import { getUserId, IdentifierManager, removeUserId, setUserId } from "./hackle/user/index.browser"
import "core-js/features/promise"
import "core-js/features/array"
import BrowserEventProcessor, { ExposureEventDedupDeterminer } from "./hackle/event/processor/index.browser"
import HackleCore from "./core/HackleCore"
import { GlobalErrorHandler } from "./hackle/trace/GlobalErrorHandler"
import { EventRepositoryImpl } from "./hackle/event/repository/index.browser"
import { UserManagerImpl, UserStorage } from "./hackle/user/manager/index.browser"
import { SessionManagerImpl } from "./hackle/session/manager/index.browser"
import { CookieStorage } from "./hackle/storage/CookieStorage.browser"
import { SessionEventTracker } from "./hackle/session/track/index.browser"
import { KeyTransformStorage, ListStorage, WindowLocalStorage } from "./core/internal/storage/Storage"
import { MonitoringMetricRegistry } from "./core/internal/metrics/monitoring/MonitoringMetricRegistry"
import { Metrics } from "./core/internal/metrics/Metrics"
import { HackleUserManualOverrideStorage } from "./core/internal/evaluation/target/ManualOverrideStorage"
import { HackleUserExplorerBase, HackleUserExplorerImpl } from "./core/internal/user/UserExplorer"
import { Urls } from "./hackle/http/Urls"
import { InAppMessageHiddenLocalStorage } from "./hackle/iam/storage/InAppMessageHiddenLocalStorage"
import { HackleCoreContext } from "./core/internal/HackleCoreContext"
import TargetMatcher from "./core/internal/evaluation/match/TargetMatcher"
import {
  InAppMessageActionHandlerFactory,
  InAppMessageCloseActionHandler,
  InAppMessageHiddenActionHandler,
  InAppMessageLinkActionHandler,
  InAppMessageLinkAndCloseActionHandler,
  InAppMessageLinkNewTabActionHandler,
  InAppMessageLinkNewTabAndCloseActionHandler,
  InAppMessageLinkNewWindowActionHandler,
  InAppMessageLinkNewWindowAndCloseActionHandler
} from "./hackle/iam/ui/event/InAppMessageActionHandler"
import { InAppMessageEventTracker } from "./hackle/iam/ui/event/InAppMessageEventTracker"
import { InAppMessageUi } from "./hackle/iam/ui/InAppMessageUi"
import InAppMessageManager from "./hackle/iam/trigger/InAppMessageManager"
import { InAppMessageDeterminer } from "./hackle/iam/trigger/InAppMessageDeterminer"
import { SystemClock } from "./core/internal/util/TimeUtil"
import { InAppMessageViewFactory } from "./hackle/iam/ui/view/InAppMessageViewFactory"
import { InAppMessageRenderScriptLoader } from "./hackle/iam/ui/script/InAppMessageRenderScriptLoader"
import { User } from "./core/internal/model/model"
import { isSameUser } from "./core/internal/user/UserManager"
import {
  InAppMessageEventTriggerFrequencyCapDeterminer,
  InAppMessageEventTriggerRuleDeterminer
} from "./hackle/iam/trigger/InAppMessageEventTriggerDeterminer"
import { InAppMessageImpressionStorage } from "./hackle/iam/storage/InAppMessageImpressionStorage"
import {
  InAppMessageActionEventProcessor,
  InAppMessageCloseEventProcessor,
  InAppMessageEventProcessorFactory,
  InAppMessageImpressionEventProcessor
} from "./hackle/iam/ui/event/InAppMessageEventProcessor"
import { InAppMessageEventHandler } from "./hackle/iam/ui/event/InAppMessageEventHandler"
import { InAppMessageEventMatcher } from "./hackle/iam/trigger/InAppMessageEventMatcher"
import {
  InAppMessageActionInteractionHandler,
  InAppMessageCloseInteractionHandler,
  InAppMessageInteractionHandlerFactory
} from "./hackle/iam/ui/event/InAppMessageInteractionHandler"
import {
  UrlLinkHandler,
  UrlLinkNewTabHandler,
  UrlLinkNewWindowHandler,
  UrlReplaceHandler
} from "./hackle/iam/ui/internal/UrlHandler"
import { CampaignManager } from "./hackle/attribution/CampaignManager"
import { CampaignStorage } from "./hackle/attribution/CampaignStorage"
import { CampaignParser } from "./hackle/attribution/CampaignParser"
import GoogleAnalyticsManager from "./hackle/external/GoogleAnalyticsManager"
import GoogleAnalyticsDeterminer from "./hackle/external/GoogleAnalyticsDeterminer"
import { Configurations, InternalConfig } from "./hackle/configuration/index.browser"
import { CompositeSynchronizer } from "./core/internal/sync/CompositeSynchronizer"
import { PollingSynchronizer } from "./core/internal/sync/PollingSynchronizer"
import { TimeoutScheduler } from "./core/internal/scheduler/TimeoutScheduler"
import { HttpWorkspaceFetcher } from "./core/internal/workspace/HttpWorkspaceFetcher"
import { DefaultUserCohortFetcher } from "./core/internal/user/UserCohortFetcher"
import { WorkspaceManager } from "./core/internal/workspace/WorkspaceManager"
import { BrowserEventDispatcher } from "./hackle/event/dispatcher/index.browser"
import { EventTransport } from "./core/internal/event/EventTransport"
import { BrowserEventRetryManager } from "./hackle/event/retry/index.browser"
import { DefaultEventProcessor } from "./core/internal/event/EventProcessor"
import { Transport } from "./core/internal/transport/Transport"
import { Transports } from "./core/internal/transport/Transports"
import { XhrTransport } from "./core/internal/transport/XhrTransport"
import { BeaconTransport } from "./core/internal/transport/BeaconTransport"
import InvocatorFactory from "./core/internal/invocator/InvocatorFactory"
import { SplitUrlManager } from "./hackle/split/SplitUrlManager"
import { SplitUrlDeterminer } from "./hackle/split/SplitUrlDeterminer"
import { SplitUrlMatcher } from "./hackle/split/SplitUrlMatcher"
import { SplitUrlRedirector } from "./hackle/split/SplitUrlRedirector"
import { SplitUrlResolver } from "./hackle/split/SplitUrlResolver"
import { SplitUrlRedirectedCookieStorage } from "./hackle/split/SplitUrlRedirectedCookieStorage"

const log = Logger.log

let hackleClientCache: HackleClient | null = null

interface Config {
  user?: User

  debug?: boolean
  auto_track_page_view?: boolean
  pollingIntervalMillis?: number
  exposureEventDedupIntervalMillis?: number
  sessionTimeoutMillis?: number
  devTool?: DevTools["manager"]
  autoOpenDevTool?: boolean
  sameSiteCookie?: string
  secureCookie?: boolean

  [key: string]: any
}

function createInstance(sdkKey: string, _config?: Config): HackleClient {
  // Config
  const config = Configurations.config(_config)

  // Logging
  Configurations.logging(config)

  // check WebApp client
  try {
    if (isHackleWebApp(window) && sdkKey === window._hackleApp.getAppSdkKey()) {
      const hackleWebAppClient = resolveHackleWebAppClientOrNull(
        window._hackleApp.getInvocationType(),
        new InvocatorFactory()
      )
      if (hackleWebAppClient) {
        return hackleWebAppClient
      }
    }
  } catch (e) {
    log.error(`Unexpected exception while check WebApp client: ${e}`)
  }

  // SDK
  const sdk = Configurations.sdk(sdkKey, config)

  // check cache
  if (hackleClientCache) {
    log.debug("use already exists hackleClient")
    return hackleClientCache
  }

  // _hackle_id
  IdentifierManager.initialize()

  // Storage
  const localStorage = new WindowLocalStorage()
  const cookieStorage = CookieStorage.getInstance({
    days: COOKIE_EXPIRE_DAYS,
    isCrossSubdomain: true,
    sameSite: config.sameSiteCookie,
    isSecure: config.secureCookie
  })

  // Beacon
  let isBeaconSupported = Configurations.isBeaconSupported()

  // Transport
  const defaultTransport = Transports.intercept(sdk, XhrTransport.create())
  const beaconTransport = Transports.intercept(sdk, BeaconTransport.create())
  let shutdownTransport: Transport = defaultTransport
  if (isBeaconSupported) {
    shutdownTransport = beaconTransport
  }

  // Synchronizer
  const compositeSynchronizer = new CompositeSynchronizer()
  const pollingSynchronizer = new PollingSynchronizer(
    compositeSynchronizer,
    new TimeoutScheduler(),
    Configurations.pollingIntervalMillis(config)
  )

  // WorkspaceManager
  const httpWorkspaceFetcher = new HttpWorkspaceFetcher(Urls.clientFetch(config.sdkUrl, sdk.key), defaultTransport)
  const workspaceManager = new WorkspaceManager(httpWorkspaceFetcher)
  compositeSynchronizer.add("workspace", workspaceManager)

  // UserManager
  const cohortFetcher = new DefaultUserCohortFetcher(Urls.userCohorts(config.sdkUrl), defaultTransport)
  const userStorage = new UserStorage(KeyTransformStorage.postfix(cookieStorage, `_${sdkKey}`))
  const previousUser = userStorage.getUser()
  const initUser = config.user || null
  const userManager = new UserManagerImpl(
    getUserId(),
    userStorage,
    cohortFetcher,
    SystemClock.instance,
    previousUser,
    initUser
  )
  compositeSynchronizer.add("cohort", userManager)

  // SessionManager
  const sessionManager = new SessionManagerImpl(
    config.sessionTimeoutMillis,
    KeyTransformStorage.postfix(cookieStorage, `_${sdkKey.slice(8)}`)
  )
  userManager.addListener(sessionManager)

  // EventProcessor
  const repository = new EventRepositoryImpl(localStorage, `${LOCAL_STORAGE_KEY_PREFIX}_${sdkKey}`, BROWSER_BATCH_SIZE)
  const eventTransport = new EventTransport(Urls.clientDispatch(config.eventUrl), defaultTransport)
  let shutdownEventTransport: EventTransport = eventTransport
  if (isBeaconSupported) {
    shutdownEventTransport = new EventTransport(Urls.beaconDispatch(config.eventUrl, sdk.key), beaconTransport)
  }
  const retryManager = new BrowserEventRetryManager(repository, eventTransport)
  const eventDispatcher = new BrowserEventDispatcher(eventTransport, shutdownEventTransport, retryManager)
  const dedupDeterminer = new ExposureEventDedupDeterminer(Configurations.dedupIntervalMillis(config))
  const defaultEventProcessor = new DefaultEventProcessor(
    eventDispatcher,
    BROWSER_BATCH_SIZE,
    new TimeoutScheduler(),
    BROWSER_FLUSH_INTERVAL
  )
  const browserEventProcessor = new BrowserEventProcessor(
    defaultEventProcessor,
    dedupDeterminer,
    sessionManager,
    userManager
  )

  // Core
  const abOverrideStorage = new HackleUserManualOverrideStorage(
    new ListStorage(cookieStorage, `${OVERRIDE_AB_STORAGE_PREFIX}_${sdkKey.slice(0, 8)}`)
  )
  const ffOverrideStorage = new HackleUserManualOverrideStorage(
    new ListStorage(cookieStorage, `${OVERRIDE_FF_STORAGE_PREFIX}_${sdkKey.slice(0, 8)}`)
  )
  const inAppMessageHiddenStorage = new InAppMessageHiddenLocalStorage(
    KeyTransformStorage.prefix(localStorage, `${IAM_HIDE_STORAGE_PREFIX}_${sdkKey.slice(0, 8)}_`)
  )
  const inAppMessageImpressionStorage = new InAppMessageImpressionStorage(
    KeyTransformStorage.prefix(localStorage, `${IAM_IMPRESSION_STORAGE_PREFIX}_${sdkKey.slice(0, 8)}_`)
  )
  const core = HackleCore.create(
    workspaceManager,
    browserEventProcessor,
    [abOverrideStorage, ffOverrideStorage],
    inAppMessageHiddenStorage
  )

  // CampaignManager, SessionTracker
  const campaignManager = new CampaignManager(
    new CampaignStorage(KeyTransformStorage.postfix(cookieStorage, `_${sdkKey.slice(0, 8)}`)),
    new CampaignParser(),
    core,
    userManager
  )
  const sessionEventTracker = new SessionEventTracker(userManager, core)

  // Add SessionListener
  // CampaignManager MUST be added before SessionTracker to include campaign properties in session events.
  sessionManager.addListener(campaignManager)
  sessionManager.addListener(sessionEventTracker)

  // InAppMessage
  const inAppMessageEventTracker = new InAppMessageEventTracker(core)
  const inAppMessageActionHandlerFactory = new InAppMessageActionHandlerFactory([
    new InAppMessageCloseActionHandler(),
    new InAppMessageLinkActionHandler(new UrlLinkHandler()),
    new InAppMessageLinkAndCloseActionHandler(new UrlLinkHandler()),
    new InAppMessageHiddenActionHandler(inAppMessageHiddenStorage, SystemClock.instance),
    new InAppMessageLinkNewWindowActionHandler(new UrlLinkNewWindowHandler()),
    new InAppMessageLinkNewWindowAndCloseActionHandler(new UrlLinkNewWindowHandler()),
    new InAppMessageLinkNewTabActionHandler(new UrlLinkNewTabHandler()),
    new InAppMessageLinkNewTabAndCloseActionHandler(new UrlLinkNewTabHandler())
  ])
  const inAppMessageEventProcessorFactory = new InAppMessageEventProcessorFactory([
    new InAppMessageImpressionEventProcessor(inAppMessageImpressionStorage),
    new InAppMessageActionEventProcessor(inAppMessageActionHandlerFactory),
    new InAppMessageCloseEventProcessor()
  ])
  const inAppMessageEventHandler = new InAppMessageEventHandler(
    SystemClock.instance,
    inAppMessageEventTracker,
    inAppMessageEventProcessorFactory
  )
  const inAppMessageInteractionHandlerFactory = new InAppMessageInteractionHandlerFactory([
    new InAppMessageCloseInteractionHandler(),
    new InAppMessageActionInteractionHandler(inAppMessageEventHandler)
  ])
  const inAppMessageRenderScriptLoader = new InAppMessageRenderScriptLoader(
    Urls.inAppMessageRenderer(config.cdnUrl, config.IAM_RENDERER_VERSION_HEADER)
  )

  const inAppMessageUi = new InAppMessageUi(
    new InAppMessageViewFactory(inAppMessageRenderScriptLoader),
    inAppMessageEventHandler,
    inAppMessageInteractionHandlerFactory
  )
  const inAppMessageEventMatcher = new InAppMessageEventMatcher(
    new InAppMessageEventTriggerRuleDeterminer(HackleCoreContext.get<TargetMatcher>("targetMatcher")),
    new InAppMessageEventTriggerFrequencyCapDeterminer(inAppMessageImpressionStorage)
  )
  const inAppMessageDeterminer = new InAppMessageDeterminer(workspaceManager, inAppMessageEventMatcher, core)
  const inAppMessageManager = new InAppMessageManager(defaultEventProcessor, inAppMessageDeterminer, inAppMessageUi)

  // GA Integration
  const googleAnalyticsDeterminer = new GoogleAnalyticsDeterminer(workspaceManager)
  const googleAnalyticsManager = new GoogleAnalyticsManager(defaultEventProcessor, googleAnalyticsDeterminer)

  // SplitUrl
  const splitUrlMatcher = new SplitUrlMatcher(HackleCoreContext.get<TargetMatcher>("targetMatcher"))
  const splitUrlRedirectedCheckStorage = new SplitUrlRedirectedCookieStorage(
    cookieStorage,
    SPLIT_URL_REDIRECT_CHECK_COOKIE_KEY,
    { days: SPLIT_URL_REDIRECT_CHECK_COOKIE_EXPIRE_DAYS }
  )
  const splitUrlDeterminer = new SplitUrlDeterminer(
    workspaceManager,
    splitUrlMatcher,
    splitUrlRedirectedCheckStorage,
    core
  )
  const splitUrlRedirector = new SplitUrlRedirector(
    new SplitUrlResolver(),
    new UrlReplaceHandler(),
    splitUrlRedirectedCheckStorage
  )
  const splitUrlManager = new SplitUrlManager(defaultEventProcessor, splitUrlDeterminer, splitUrlRedirector)

  // UserExplorer
  const userExplorer = new HackleUserExplorerImpl(core, userManager, abOverrideStorage, ffOverrideStorage)
  const isValidDevToolConfig = config.devTool?.userExplorer && typeof config.devTool?.userExplorer === "function"
  const devTools = config.devTool && isValidDevToolConfig ? { manager: config.devTool, userExplorer } : undefined

  // Metrics
  const monitoringMetricRegistry = new MonitoringMetricRegistry(
    Urls.monitoring(config.monitoringUrl),
    defaultTransport,
    shutdownTransport,
    new TimeoutScheduler(),
    METRIC_FLUSH_INTERVAL
  )
  Metrics.addRegistry(monitoringMetricRegistry)

  // Instantiate
  pollingSynchronizer.start()
  const hackleClient = new HackleClientImpl(core, pollingSynchronizer, sessionManager, userManager, devTools)
  hackleClientCache = hackleClient

  // Post instantiate
  const autoOpenDevTool = () => {
    if (config.autoOpenDevTool) {
      if (!config.devTool) {
        log.error("DevTool is not provided")
      } else {
        hackleClient.showUserExplorer()
      }
    }
  }

  const flushRetryEvent = () => {
    retryManager.flush().catch((e) => log.warn(`Failed to flush retry events: ${e}`))
  }

  const flush = () => {
    removeUserId()
    sessionManager.updateLastEventTime(new Date().getTime())
    hackleClient.close()
    Metrics.globalRegistry.close()
  }

  const refresh = () => {
    const timestamp = Date.now()
    const currentUser = userManager.currentUser

    // Session Refresh
    const currentSessionId = sessionManager.currentSessionId
    let newSessionId: string
    if (isSameUser(previousUser, currentUser)) {
      newSessionId = sessionManager.startNewSessionIfNeeded(currentUser, timestamp)
    } else {
      newSessionId = sessionManager.startNewSession(previousUser, currentUser, timestamp)
    }

    // Campaign Refresh
    if (newSessionId === currentSessionId) {
      // If new session is started, CampaignManager automatically restarts the campaign.
      // Attempts to cover when session is not newly started, but the campaign changes.
      campaignManager.startNewCampaignIfNeeded(currentUser, timestamp)
    }
  }

  window.addEventListener("onpagehide" in window ? "pagehide" : "unload", flush)
  window.addEventListener("pageshow", flushRetryEvent)
  document.addEventListener("visibilitychange", () => {
    if (!document.hidden) {
      flushRetryEvent()
    }
  })

  autoOpenDevTool()
  refresh()

  hackleClient.onReady(() => {
    new GlobalErrorHandler().install(core, userManager)
    _installPageViewAutoTrack(hackleClient, config)
  })

  return hackleClient
}

function _installPageViewAutoTrack(client: HackleClient, config: InternalConfig) {
  if (!config.auto_track_page_view) {
    return
  }
  client.trackPageView()

  function customEvent(type: string): Event {
    if (typeof window.Event === "function") return new Event(type)

    const params = { bubbles: false, cancelable: false, detail: undefined }
    const evt = document.createEvent("CustomEvent")
    evt.initCustomEvent(type, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  try {
    history.pushState = ((f) =>
      function pushState() {
        try {
          // @ts-ignore
          var ret = f.apply(this, arguments)
          window.dispatchEvent(customEvent("locationchange"))
          return ret
        } catch (e) {
          if (e instanceof Error) {
            log.error(e)
          } else {
            try {
              log.error(e as string)
            } catch (ex) {}
          }
        }
      })(history.pushState)

    history.replaceState = ((f) =>
      function replaceState() {
        try {
          // @ts-ignore
          var ret = f.apply(this, arguments)
          window.dispatchEvent(customEvent("locationchange"))
          return ret
        } catch (e) {
          if (e instanceof Error) {
            log.error(e)
          } else {
            try {
              log.error(e as string)
            } catch (ex) {}
          }
        }
      })(history.replaceState)

    window.addEventListener("popstate", () => {
      try {
        window.dispatchEvent(customEvent("locationchange"))
      } catch (e) {
        if (e instanceof Error) {
          log.error(e)
        } else {
          try {
            log.error(e as string)
          } catch (ex) {}
        }
      }
    })
    window.addEventListener("locationchange", () => {
      try {
        client.trackPageView()
      } catch (e) {
        if (e instanceof Error) {
          log.error(e)
        } else {
          try {
            log.error(e as string)
          } catch (ex) {}
        }
      }
    })
  } catch (e) {
    if (e instanceof Error) {
      log.error(e)
    } else {
      try {
        log.error(e as string)
      } catch (ex) {}
    }
  }
}

export { PropertyOperationsBuilder, PropertyOperations } from "./hackle/property/PropertyOperations"
export { HackleUserExplorerBase }
export { createInstance }
export { getUserId }
export { setUserId }
export { removeUserId }
export { HackleClient }
export { PageView }
export { Logger }
export { Config }
export { EmptyParameterConfig, ParameterConfig } from "./core/internal/config/ParameterConfig"

export * from "./core/internal/model/model"

export default {
  createInstance,
  getUserId,
  setUserId,
  removeUserId,
  Logger
}
